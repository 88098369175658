import axios from "axios";
const apiPath =  process.env.REACT_APP_API_URL || '/api/'//AZURE LINKED API; //"http://localhost:7071/api/";
var token = localStorage.getItem('signed_token')
const token_key = JSON.parse(token)

const sendRequest = async (method, api, req) => {
    let headers = {};
    headers['Authorization'] = `Bearer ${token_key.token}` || req;
    try {

        const response = await axios({
            method,
            url: `${apiPath}${api}`,
            headers,
            data: req
        })
        .then((res) => res)
        .catch((error) => {
            if (error.response.data === "Unauthorized") {
                sessionStorage.setItem('historyUrl', window.location.pathname);
                localStorage.clear();
                window.location.href = '/login';
            }
            throw error;
        });
        

        return response.data;
    } catch (error) {
        throw error;
    }
};

const sendRequestAuth = (method, api, req) => {    
    let headers = {};
    if (req.codeverifier) //used by auth callback
        headers['codeverifier'] = req.codeverifier;

    headers['requestoruri'] = window.location.origin;
    
    headers['authorizationcode'] = req.authorizationcode || req;

    const config = {
        method,
        url: `${apiPath}${api}`,
        headers : headers,
        data : req
    };
    if(method === 'get') delete config.data;
    return axios(config)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });

 
};

export const get = (api, req) => sendRequest('get', api, req);
export const post = (api, req) => sendRequest('post', api, req);
export const getAuth = (api, req) => sendRequestAuth('get', api, req);
export const postAuth = (api, req) => sendRequestAuth('post', api, req);
