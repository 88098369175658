import React, { Component, Suspense, useEffect, useState, useTransition } from 'react'
import { BrowserRouter as Router, Routes, Navigate, Route, HashRouter, Switch, useLocation  } from 'react-router-dom';
import './scss/style.scss'
import loginService from './service/api/login.service.js'
import PrivateRoutes from './PrivateRoutes';
const token = localStorage.getItem('signed_token') ? JSON.parse(localStorage.getItem('signed_token')) : {token: null};

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login.js'))
const Callback = React.lazy(() => import('./views/pages/login/callback'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404.js'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500.js'))

// Function to format date-time including milliseconds
const formatDateTime = (timestamp) => {
const date = new Date(timestamp * 1000);
const month = date.toLocaleString('default', { month: 'short' });
const day = date.getDate();
const year = date.getFullYear();
const hours = date.getHours();
const minutes = date.getMinutes();
const seconds = date.getSeconds();
const milliseconds = date.getMilliseconds();

// Pad single digit values with leading zero
const padZero = (value) => {
    return value < 10 ? '0' + value : value;
};

return `${month}. ${padZero(day)}, ${year} T ${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}.${milliseconds}`;
};



const App = () => {
    const [authInfo, setAuthInfo] = useState();
    const [pictureData, setPictureData] = useState(null);

    useEffect(() => {
        getAuth(token);
    }, []);

     const getAuth = (token) => {
        try {
            
            if(token.token === null || token.token === undefined || token.token === ''){
                return false;
            } else {
                const token_key = token;//JSON.parse(token) 
                const decode = JSON.parse(atob(token_key.token.split('.')[1])); // Decode and parse JWT token
                const user = {}; // Object to store user data
                const reqFields = ['iat', 'nbf', 'exp', 'email', 'name', 'oid'];
                
                reqFields.forEach(field =>  {
                    if (['iat', 'nbf', 'exp'].includes(field)) {
                        user[field] = formatDateTime(decode[field]); // Format UNIX timestamp to "Month Day Year"
                    } else {
                        user[field] = decode[field];
                    }
                });
                // Set the user data to state
                setAuthInfo(user);
                
                // Set the picture buffer to state
                setPictureData(token_key.img);
                
                return true
            }
            
        } catch (error) {
            console.error(error.message, error)
            return false
        }
    };



    return (
        <Router>
          <Routes>
            <Route element={<PrivateRoutes token={token}/>}>
                <Route path="*" element={<DefaultLayout authInfo={authInfo} pictureData={pictureData}/>} />
            </Route>
            <Route path="/page404" element={<Page404 />} />
            <Route path="/page500" element={<Page500 />} />
            <Route path="/login" element={authInfo ? <Navigate to="/dashboard" replace /> : <Login />} />
            <Route path="/login/callback" element={<Callback />} />
          </Routes>
        </Router>
    )
}

export default App
