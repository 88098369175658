import { Outlet, Navigate } from 'react-router-dom'

const PrivateRoutes = ({token}) => {

    if (token.token === null || token.token === undefined || token.token === '') {
        return <Navigate to="/login" />
    }
    return <Outlet />
}

export default PrivateRoutes